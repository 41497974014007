export const links = {
	de: [
		{
			title: 'Unternehmen',
			href: '/',
			links: [
				{
					title: 'Über uns',
					href: '/de/uber-uns',
				},
				{
					title: 'Unsere Ansprechpartner',
					href: '/de/unsere-ansprechpartner',
				},
				{
					title: 'Qualität und Zertifizierungen',
					href: '/de/qualitat-zertifizierungen',
				},
				{
					title: 'Webinar',
					href: '/de/webinar',
				},
				{
					title: 'Newsletter',
					href: '/de/newsletter',
				},
				/*  {
					title: 'Blog',
					href: '/de/blog',
				},
				, */
			],
		},

		{ title: 'Dienstleistungen', href: '/de/dienstleistungen' },
		/* {
			title: 'Kompetenzen',
			href: '/de/kompetenzen',
			links: [
				{
					title: 'test',
					href: '/de/test',
				},
			],
		}, */
		{
			title: 'Technologien',
			href: '/de/technologien',
		},

		{ title: 'Karriere', href: '/de/karriere/' },

		{ title: 'Kontakt', href: '/de/kontakt/' },
	],
	en: [
		{
			title: 'Company',
			href: '/en/',
			links: [
				{
					title: 'About us',
					href: '/en/about-us',
				},
				{
					title: 'Contact Persons',
					href: '/en/contact-persons',
				},
				{
					title: 'Quality & Certifications',
					href: '/en/quality-certifications',
				},
				{
					title: 'Webinar',
					href: '/en/webinar',
				},
				{
					title: 'Newsletter',
					href: '/en/newsletter',
				},
				/*  {
					title: 'Blog',
					href: '/en/blog',
				},
				 */
			],
		},

		{ title: 'Services', href: '/en/services/' },
		/* {
			title: 'Competencies',
			href: '/en/competencies',
			links: [
				{
					title: 'test',
					href: '/en/test',
				},
			],
		}, */
		{
			title: 'Technologies',
			href: '/en/technologies',
		},

		{ title: 'Carrers', href: '/en/carrers/' },
		{ title: 'Contact', href: '/en/contact/' },
	],
};

export interface LinkI {
	title: string;
	href: string;
	links?: LinkI[];
}
