export interface FooterLinks {
	titles: {
		title: string;
		href: string;
	}[];
	links?: {
		title: string;
		href: string;
	}[];
}

export const footerLinks = {
	de: [
		{
			titles: [
				{
					title: 'Unternehmen',
					href: '/',
				},
			],
			links: [
				{
					title: 'Über uns',
					href: '/de/uber-uns',
				},
				{
					title: 'Unsere Ansprechpartner',
					href: '/de/unsere-ansprechpartner/',
				},
				{
					title: 'Qualität und Zertifizierungen',
					href: '/de/qualitat-zertifizierungen/',
				},
				{
					title: 'Webinar',
					href: '/de/webinar/',
				},
				{
					title: 'Newsletter',
					href: '/de/newsletter',
				},
				/* 
				{
					title: 'Blog',
					href: '/en/blog',
				},
				 */
			],
		},
		/*  {
			titles: [
				{
					title: 'Competencies',
					href: '/',
				},
			],
			links: [
				{
					title: 'Main areas of production',
					href: '/en/main-areas-production',
				},
				{
					title: 'Machinery & equipment',
					href: '/en/machinery-equipment',
				},
				{
					title: 'Test procedures',
					href: '/en/test-procedures',
				},
			],
		}, */
		{
			titles: [
				{
					title: 'Dienstleistungen',
					href: '/de/dienstleistungen',
				},
				{
					title: 'Technologien',
					href: '/de/technologien',
				},
				{
					title: 'Karriere',
					href: '/de/karriere',
				},
			],
		},
	],
	en: [
		{
			titles: [
				{
					title: 'Company',
					href: '/',
				},
			],
			links: [
				{
					title: 'About us',
					href: '/en/about-us',
				},
				{
					title: 'Contact Persons',
					href: '/en/contact-persons',
				},
				{
					title: 'Quality & Certifications',
					href: '/en/quality-certifications',
				},
				{
					title: 'Webinar',
					href: '/en/webinar',
				},
				{
					title: 'Newsletter',
					href: '/en/newsletter',
				},
				/*
				{
					title: 'Blog',
					href: '/en/blog',
				},
				 */
			],
		},
		/*  {
			titles: [
				{
					title: 'Competencies',
					href: '/',
				},
			],
			links: [
				{
					title: 'Main areas of production',
					href: '/en/main-areas-production',
				},
				{
					title: 'Machinery & equipment',
					href: '/en/machinery-equipment',
				},
				{
					title: 'Test procedures',
					href: '/en/test-procedures',
				},
			],
		},
		 */
		{
			titles: [
				{
					title: 'Services',
					href: '/en/services',
				},
				{
					title: 'Technologies',
					href: '/en/technologies',
				},
				{
					title: 'Carrers',
					href: '/en/carrers',
				},
			],
		},
	],
};
